<template>
  <div>
    <b-row class="mt-3">
      <b-col>
        <b-table
          responsive
          striped
          hover
          :fields="fields"
          :items="items"
          :busy="isBusy"
          show-empty
          empty-text="No matching records found"
        >
          <template v-slot:cell(excelField)="data">
            <div class="d-flex justify-content-center">
              <div>{{ data.item.excelField }}</div>
              <b-form-select
                value=""
                @change="getExcelField($event, data.item.name)"
              >
                <template #first>
                  <b-form-select-option value="" disabled
                    >Please Select</b-form-select-option
                  >
                </template>
                <option
                  v-for="(field, index) in excelFields"
                  :key="index"
                  :value="field"
                >
                  {{ field }}
                </option>
              </b-form-select>
            </div>
          </template>
          <template v-slot:cell(is_required)="data">
            <div>{{ data.item.is_required === "1" ? "Yes" : "No" }}</div>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-black my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong class="ml-2">Loading...</strong>
            </div>
          </template>
        </b-table>
      </b-col>
    </b-row>
    <b-row class="my-3">
      <b-col>
        <b-button
          size="md"
          :href="pageType === 1 ? '/customer' : '/transaction'"
          class="btn-cancel"
          >Cancel</b-button
        >
      </b-col>
      <b-col class="text-right">
        <b-button class="main-color btn-save" size="md" @click="submitMap"
          >Save</b-button
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    fields: {
      required: true,
      type: Array,
    },
    items: {
      required: true,
      type: Array,
    },
    isBusy: {
      required: true,
      type: Boolean,
    },
    rows: {
      required: true,
      type: Number,
    },
    showingTo: {
      required: true,
      type: Number,
    },
    pageType: {
      required: true,
      type: Number,
    },
    excelFields: {
      required: true,
      type: Array,
    },
    form: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      selected: "",
    };
  },
  methods: {
    submitMap() {
      if (this.form.list_map_require.length === 0) {
        let msg = "Please Select Excel Field";
        this.$swal(msg, {
          icon: "warning",
        });
      } else {
        if (this.items.length === this.form.list_map_require.length) {
          this.$emit("submitForm", this.form);
        } else {
          let msg = `Please Select Excel Field`;
          this.$swal(msg, {
            icon: "warning",
          });
        }
      }
    },
    getExcelField(evt, name) {
      if (this.form.list_map_require.length === 0) {
        this.form.list_map_require.push({
          require_column: name,
          map_require_column: evt,
        });
      } else {
        const findName = this.form.list_map_require.find(
          (el) => el.require_column === name
        );
        if (!findName) {
          this.form.list_map_require.push({
            require_column: name,
            map_require_column: evt,
          });
        } else {
          findName.map_require_column = evt;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: #f0b6c7;
  color: var(--font-color);
}
</style>